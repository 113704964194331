import './App.css';
import * as AllIcons from "@KomdatLib/icons";
import packagejson from "@KomdatLib/icons/package.json";
import Logo from './logo.svg';
import {useRef, useState, useEffect} from "react";

function App() {

    const [Icons, setIcons] = useState(AllIcons);
    const [theme, setTheme] = useState('dark'); // default to dark mode
    const cardsRef = useRef(null);

    const IconNames = Object.keys(Icons);

    const searchIcons = (e) => {

        let foundIcons = {};

        if(e.target.value && e.target.value.length > 0) {
            Object.keys(AllIcons).forEach(icon => {
                if(icon.toLowerCase().includes(e.target.value.toLowerCase())) {
                    foundIcons[icon] = AllIcons[icon];
                }
            });
        } else {
            foundIcons = AllIcons;
        }

        setIcons(foundIcons);
    };

    useEffect(() => {
        const cards = cardsRef.current.querySelectorAll('.card');

        const handleMouseMove = (event) => {
            cards.forEach(card => {
                const rect = card.getBoundingClientRect();
                const x = event.clientX - rect.left;
                const y = event.clientY - rect.top;
                card.style.setProperty("--xPos", `${x}px`);
                card.style.setProperty("--yPos", `${y}px`);
            });
        };

        const wrapper = cardsRef.current;
        wrapper.addEventListener('mousemove', handleMouseMove);

        return () => {
            wrapper.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    const toggleTheme = () => {
        if (theme === 'dark') {
            setTheme('light');
            document.body.classList.remove('dark');
            document.body.classList.add('light');
        } else {
            setTheme('dark');
            document.body.classList.remove('light');
            document.body.classList.add('dark');
        }
    };

    useEffect(() => {
        if (theme === 'light') {
            document.body.classList.remove('dark');
            document.body.classList.add('light');
        } else {
            document.body.classList.remove('light');
            document.body.classList.add('dark');
        }
    }, [theme]);

    return (
        <div className="App">
          <header className="App-header">
              <div style={{display: 'flex', flexDirection: 'row'}}>
                  <div style={{flex: 1}}>
                      <h1>Komdat Icon Library {packagejson.version}</h1>
                      <a>Komdat Datentechnik für den Kommunalbetrieb</a>

                  </div>
                  <div style={{width: '50px'}}>
                      <img src={Logo} width={50} />
                  </div>
              </div>

          </header>
            <div className={"Search-wrapper"}>
                <div className="mode-toggle noselect" onClick={toggleTheme}>
                    <h6 className="label-dark">Dark</h6>
                    <div className="toggle-switch"></div>
                    <h6 className="label-light">Light</h6>
                </div>
                <div className={"Search-container"}>
                    <input className={"SearchInput"} onChange={searchIcons} placeholder={"Suchen..."} />
                </div>
            </div>
            {/*<div className={"App-body"}>
                {Object.values(Icons).map(((Icon, index) => <div onClick={() => navigator.clipboard.writeText(`<${IconNames[index]} />`)} key={`icon-container-${index}`} className={"Icon-container"}><span className={"Icon"}><Icon /></span><span className={"Label"}>{IconNames[index]}</span></div>))}
            </div>*/}
            <div ref={cardsRef} className="cards">

                {Object.values(Icons).map(((Icon, index) =>
                    <div
                        onClick={() => navigator.clipboard.writeText(`<${IconNames[index]} />`)}
                        key={`icon-container-${index}`}
                        className={"card"}
                    >
                        <div className="card-content">
                            <i className={""}>
                                <Icon />
                            </i>
                            <h2 className={""}>
                                {IconNames[index]}
                            </h2>
                            </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;
